import Point from "ol/geom/Point";
import { Feature } from "ol";
export const TIME_KEY = "time_wall_ns";
export default class StrikeManagerV2 {
    constructor(vectorSource, baseline = 0) {
        this.vs = vectorSource;
        this.strikes = {}; // key = time, value = OL object reference
        this.baseline = baseline;
    }
    setBaseline(baseline) {
        const keys = Object.keys(this.strikes).map(parseInt);
        keys.forEach((key) => {
            if (key < baseline) {
                this.vs.removeFeature(this.strikes[key]);
                delete this.strikes[key.toString()];
            }
        });
        this.baseline = baseline;
    }
    addStrike(lon, lat, timestamp) {
        const strike = new Feature(new Point([lon, lat]));
        strike.set(TIME_KEY, timestamp);
        this.strikes[timestamp.toString()] = strike;
        this.vs.addFeature(strike);
    }
}
